import Router from './routes/Router';
import { Logo } from 'components/elements/Logo';
import { SectionContainer } from 'components/layout/SectionContainer';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router';

import { useLocation } from 'react-router-dom';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle page change
  useEffect(() => {
    console.log('Location changed!');
    console.log(location);
    if (!window.adobeDataLayer) {
      return;
    }
    if (location.pathname === '/') {
      console.log("Home selected");
      window.adobeDataLayer.push({
        "event": "onload-page",
        "page": {
          "pageInfo": {
            "title": "Home",
            "pageName": "home",
            "errorPage": "false"
          },
          "businessUnit": "wbs",
          "country": "gbr",
          "project": "/wbs/gbr/solar-configurator"
        },
      })
    }
    if (location.pathname == "/elevations/measurements") {
      if (location.search == "?type=single") {
        console.log("Single selected");
        window.adobeDataLayer.push({
            "event": "onload-page",
            "page": {
                "pageInfo": {
                    "title": "Single Elevation",
                    "pageName": "single-elevation",
                    "errorPage": "false"
                },
                "businessUnit": "wbs",
                "country": "gbr",
                "project": "/wbs/gbr/solar-configurator"
            },
        })
      }
      if (location.search == "?type=multi") {
        console.log("Multiple selected");
        window.adobeDataLayer.push({
            "event": "onload-page",
            "page": {
                "pageInfo": {
                    "title": "Multiple Elevation",
                    "pageName": "multiple-elevation",
                    "errorPage": "false"
                },
                "businessUnit": "wbs",
                "country": "gbr",
                "project": "/wbs/gbr/solar-configurator"
            },
        })
      }
    }
    if (location.pathname.indexOf("/elevations/configure") >= 0) {
      console.log("Measurements selected");
      window.adobeDataLayer.push({
            "event": "onload-page",
            "page": {
                "pageInfo": {
                    "title": "Measurements selected",
                    "pageName": "measurements-selected",
                    "errorPage": "false"
                },
                "businessUnit": "wbs",
                "country": "gbr",
                "project": "/wbs/gbr/solar-configurator"
            },
        })
    }
    if (location.pathname == "/elevations/results") {
      console.log("Final page");
      window.adobeDataLayer.push({
            "event": "onload-page",
            "page": {
                "pageInfo": {
                    "title": "Results page",
                    "pageName": "results",
                    "errorPage": "false"
                },
                "businessUnit": "wbs",
                "country": "gbr",
                "project": "/wbs/gbr/solar-configurator"
            },
        })
    }
  }, [location]);
  return (
    <ErrorBoundary>
      <div className="App flex flex-col min-h-screen">
        <SectionContainer customClassNames="pb-0 md:pb-0 px-4 mb-8 lg:mb-4 pt-4">
          <Logo />
        </SectionContainer>
        <Router />
      </div>
    </ErrorBoundary>
  );
}

export default App;
